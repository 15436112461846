@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap);
body {
  margin: 0;
  background-color: pink;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.header{
  text-align: center;
  font-size: 3rem;
  color:darkmagenta;
  text-decoration: underline  ;
  font-family:cursive;
}
hr{
  border-top:1px solid darkmagenta;
}
.text{
  width: 80%;
  margin: 1rem auto;
  font-size: 1.2rem;
  text-align: center;
  color:rgb(70, 5, 102);
  background-color: violet;
  border-radius: 50px;
  border: 5px solid darkmagenta;
  font-family: 'Lora', serif;
  padding: 5px;

}
.memory{
  font-size: 3rem;
  text-align: center;
  color: darkviolet;
  margin:1.5rem;
  font-weight: bold;
  font-family: cursive;
}
.main{
  margin: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: auto;
}



/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  margin: 3rem;
display: inline-block;
  width: 280px;
  height: 400px;
  border: 1px solid #f1f1f1;
  -webkit-perspective: 1000px;
          perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  background-color: white;

  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: rgb(5, 46, 87);
  padding: 5px;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.image{
  width:280px;
  height: 350px;
  border-radius: 10px;
}

.date{
  font-family: 'Dancing Script', cursive;
    font-size: 1.5rem;
  color: black;
}

.back-text{
  font-size: 1.8rem;
  color: white;
  font-family: 'Lora', serif;
}
#text2{
font-size: 1.6rem;
}
#im12{
  width:250px
}
#im16,#im18{
  height: 250px;
  margin: 50px 0px;
}

.bottom{
  color: white;
  font-size: 4rem;
  margin: 1rem;
  font-weight: 800;
  font-family: 'Lora', serif;
  text-align: center;

}

.button{
  background-color: transparent;
  background-color: violet;
  width:70px;
  height: 70px;

  border: 3px solid darkviolet;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  font-family: 'Dancing Script', cursive;
  font-size: 25px;
}
@media only screen and (max-width: 650px) {
  .flip-card{
    margin:1em;
    overflow-x: hidden;
    overflow-y: hidden;
  }

.header{
  font-size: 2em;
}
.text{
width: 90%;
border: 2.5px;
font-size: 0.9rem;
}
.memory{
  font-size: 1.2rem;
  margin: 2rem 0rem;
}
.button{
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-top: 2rem;
}
}
